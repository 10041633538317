<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>公司信息</el-breadcrumb-item>
      <el-breadcrumb-item>修改公司信息</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-form :model="editForm"
               :rules="editFormRules"
               ref="editFormRef"
               label-position="top">
        <el-form-item>
          <el-input v-model="editForm.name"
                    disabled></el-input>
        </el-form-item>
        <el-form-item>
          <TEditor v-model="editForm.content"
                   :baseUrl="baseUrl" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     @click="upEditForm">提交</el-button>
          <el-button @click="goBack">取消</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      editForm: {
        content: ''
      },
      baseUrl: '/logo?type=company',
      // 添加验证规则
      editFormRules: {

      }
    }
  },

  methods: {
    goBack () {
      this.$router.push('/class')
    },

    // 获取数据
    async getDate (id) {
      const { data: res } = await this.$http.get('company/' + id + '/edit')
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.editForm = res.data
    },

    // 修改后提交
    async upEditForm () {
      const { data: res } = await this.$http.put('company/' + this.editForm.id, {
        content: this.editForm.content
      })
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.$message.success('修改成功')
      // 跳转
      this.$router.push('/class')
    }
  },

  created () {
    this.getDate(this.$route.params.id)
  }
}
</script>

<style>
</style>
